$(() => {
  carouselHitbox();
  setTimeout(carouselHitbox, 500);
  $('.js-carousel__single-item').on('afterChange', (event, slick, newSlide) => {
    const element = $(`[data-slick-index=${newSlide}]`);
    const carouselText = element.find('.carousel-text');
    const binText = carouselText.text();
    const jsonText = atob(binText);
    const data = JSON.parse(jsonText);

    $('#carousel-headline').html(data.headline);
    $('#carousel-secondary').html(data.secondary);
    $('#carousel-body').html(data.body);
    $('#carousel-link').attr('href', data.link);
  });
});

function carouselHitbox() {
  document.querySelectorAll('.slick-dots li').forEach(carouselHitboxEach)
}

function carouselHitboxEach(element: Element) {
  if (!element.querySelectorAll('.dash').length) {
    let div = document.createElement('div');
    div.classList.add('dash');

    element.appendChild(div);
  }
}
